import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import "react-table/react-table.css";

import {API_URL} from '../../actions/_request';
import {api,api2} from '../../actions/_request';
import _ from "lodash";

import * as actions from '../../actions/user';
import swal from 'sweetalert2';
import moment from 'moment';
import ModalReporte from './Modal_Excel';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,Badge, Table 
} from "reactstrap";

class Users2 extends Component {
  render() {
    return (
        <div className="content">
          <Row>
            <Col md="12">
              <Card className="card-user">
                <CardBody>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
    );
  }
}

const requestData = (pageSize, page, sorted, filtered) => {    
    return new Promise((resolve, reject) => {
        // You can retrieve your data however you want, in this case, we will just use some local data.
        let filteredData ;
        var fecha='';
        var nombre_paquete='';
        var nombre_area='';
        var estado='';
        var hora='';
        var cliente='';
        var apellido='';
         var correo='';
         var id=''
        var take= pageSize;
        var skip=pageSize * page;
        var total=1; 
        var patt = new RegExp("(si|s)", "i"); 
        for (var i = filtered.length - 1; i >= 0; i--) {
          switch(filtered[i].id)
          {
            case "cliente":
              cliente=filtered[i].value;
              // console.log('cliente--->',filtered[i].value);
              break;
              case "apellido":
              apellido=filtered[i].value;
              // console.log('apellido--->',filtered[i].value);
              break;
              case "correo":
              correo=filtered[i].value;
              // console.log('correo--->',filtered[i].value);
              break;         
            case "fecha":
              fecha=filtered[i].value;
              // console.log('fecha--->',filtered[i].value);
              break;
            case "nombre_paquete":
              nombre_paquete=filtered[i].value;
              // console.log('empleado--->',filtered[i].value);
              break; 
              case "nombre_area":
              nombre_area=filtered[i].value;
              // console.log('empleado--->',filtered[i].value);
              break; 
              case "estado":
              estado=filtered[i].value;
              // console.log('empleado--->',filtered[i].value);
              break; 
            
          case "hora":
              hora=filtered[i].value;
              // console.log('rfc--->',filtered[i].value);
              break; 
          case "id":
              id=filtered[i].value;
              // console.log('rfc--->',filtered[i].value);
              break; 
          }
        }

              
              api2().get(`/getreservaciones?id=${id}&nombre_paquete=${nombre_paquete}&nombre_area=${nombre_area}&estado=${estado}&hora=${hora}&fecha=${fecha}&cliente=${cliente}&apellido=${apellido}&correo=${correo}&take=${take}&skip=${skip}`)
              .then(function(response)
              {
                if(response.status === 200)
                {
                  filteredData= response.data.datos;
                  total= response.data.total; 
                  // You can also use the sorting in your request, but again, you are responsible for applying it.
                  const sortedData = _.orderBy(
                    filteredData,
                    sorted.map(sort => {
                      return row => {
                        if (row[sort.id] === null || row[sort.id] === undefined) {
                          return -Infinity;
                        }
                        return typeof row[sort.id] === "string"
                        ? row[sort.id].toLowerCase()
                        : row[sort.id];
                      };
                    }),
                    sorted.map(d => (d.desc ? "desc" : "asc"))
                  );
                  const res = {
                    rows: sortedData,
                    pages: Math.ceil(total / pageSize)
                  };
                  // Here we'll simulate a server response with 500ms of delay.
                  //setTimeout(() => resolve(res), 1000);
                  //console.log(res);
                  resolve(res)
                }
              });   
        
    });
};



class Users extends Component {

  constructor(props) {

        super(props);

        this.state = {
             pages: null,
             loading: true,
             modal:false
        };

        this.fetchData = this.fetchData.bind(this);
         this.table2 = React.createRef();

    }

     toggle_modal=()=>{  

        this.setState({
            modal : !this.state.modal,         
        })
    } 

    componentDidMount(){
    

    // console.log('tabla-->',this.table2.current.state)
    // this.table2.current.state.filtered.push( {id: "fecha", value:moment().format('Y-MM-DD') });
    // this.fetchData(this.table2.current.state);

  }


    enviar_correo(id,correo){
      console.log('correo--->',correo)
       let self = this;
      swal.fire({
  title: `La reservación FRIDA-${id} se enviara al siguiente correo`,
  input: 'text',
  inputValue:correo,
  inputAttributes: {
    autocapitalize: 'off',
    value:correo
  },
  showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar!',
  showLoaderOnConfirm: true,
  preConfirm: (input_email) => {
    return api().get(`/send_email/${id}/${input_email}`)
      .then(response => {
        if(response.status === 200)
          {
                        
               
        }

      })
      .catch(error => {
        swal.showValidationMessage(
          `Request failed: ${error}`
        )
      })
  },
  allowOutsideClick: () => !swal.isLoading()
}).then((result) => {
   console.log('resultado--->',result)

   if(!result.dismiss)

    swal.fire({
                type: 'success',
                
                text: 'El correo Fue enviado',
                
              })
})
    }


  cambiar_estado=(id,estado,estado_texto,data)=>{
        

        // console.log('Factura------>',factura)

      let self = this;
      swal.queue([{
        title: 'Favor de Confirmar ',
        text: ` Se Cambiara el Estado a ${estado_texto} al area ${data.nombre_esp} `,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return api().get(`/cambiar_estado_area/${id}/${estado}`)
        .then(function(response)
        {
          if(response.status === 200)
          {
               swal.insertQueueStep({
                        type: 'success',
                        title: 'Se Cambio el estado correctamente'
                      })
                  self.fetchData(self.table.current.state);
           
          }
        }).catch(() => {
              swal.insertQueueStep({
                type: 'error',
                title: 'Solicitar ayuda a un Administrador',
              })
            })
        }
      }])


        
    }


     fetchData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    console.log('tabla-->',state)
    requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false
      });
      // console.log('datos--->',res.rows);
       // this.props.get(res.rows);
    });
  }


  render() {
    const {  pages, loading} = this.state;
    return (
      <div className="content">
      {this.state.modal&&
      <ModalReporte
          open={this.state.modal}
          toggle={this.toggle_modal}
        />
      }
        <Row>
          <Col >
            <Card>
              <CardHeader>
              <Row>
              <Col xs={6}>
            
              </Col>
              <Col className='text-right' xs={6}>
               <Button
                 color="warning" 
                 className="btn" onClick={(evt)=>{this.toggle_modal(); }}>
                  Reporte
                </Button>
               {this.props.auth.user.rol==99&&
              <Link to="/app/reservaciones/nuevo">
                <Button color="primary" className="mb-2" >
                    Agregar
                </Button>
              </Link>
            }
              </Col>
              </Row>
              </CardHeader>
              <CardBody>
              <ReactTable
                             getTrProps={(state, rowInfo, column) => {
                                 
                                  if(rowInfo === undefined){
                                         
                                          return{

                                          }
                                        }else{
                                           return{
                                            onDoubleClick: (e) => {
                                              
                                                  this.props.history.push('/app/reservaciones/editar/'+rowInfo.original.id);
                                             
                                              
                                            }
                                        } 
                                  
                                    }
                              }}
                              
                              
                              className = "-striped -highlight"
                              columns   = {[
                               
                                {Header: 'Folio', accessor: 'id',minWidth:50,maxWidth:75,style: { textAlign: 'center'}, },
                                {Header: 'Estado', accessor: 'estado',minWidth:150,style: { textAlign: 'center'}, 
                                Cell: (row) =>
                                    {
                                        return(
                                            <div>{this.props.auth.estados_reservacion.filter(option => option.value == row.original.estado)[0].label}</div>
                                        )
                                    },
                                     Filter: ({ filter, onChange }) =>

                                     <select name="estado" id="estado" onChange={event => onChange(event.target.value)}
                                             value={filter ? filter.value : ""}>
                                      <option value="">Todas</option>
                                      <option value="1">Pagada</option>
                                      <option value="0">Reservada - No Pagada</option>
                                      <option value="99">Cancelada</option>
                                    
                                    </select>
                                 
                                  } ,
                                   {Header: 'Area', accessor: 'nombre_area'},
                                  {Header: 'Paquete', accessor: 'nombre_paquete'},
                               
                                  {Header: 'Fecha',sortable: false, accessor: 'fecha',
                                    Filter: ({ filter, onChange }) =>
                                  <input
                                    type="date" 
                                    name="fecha"
                                    class="form-control"
                                    onChange={event => onChange(event.target.value)}
                                   value={filter ? filter.value : ''}
                                />,
                                  Cell: (row) =>
                                    {
                                        return(
                                            <div>{`${moment(new Date(row.original.fecha)).format('Y-MM-DD')} `}</div>
                                        )
                                    },},
                                  {Header: 'Hora', accessor: 'hora'},
                                  {Header: 'Nombre', accessor: 'cliente'},
                                  {Header: 'Apellido', accessor: 'apellido'},
                                  {Header: 'Correo', accessor: 'correo'},   
                                {
                                    Header: 'Controles',
                                    filterable: false,
                                    sortable: false,
                                     maxWidth:500,
                                      minWidth:150,
                                    Cell: (row) =>
                                    {
                                        return(
                                            <div className="text-center">
                                                {this.props.auth.user.rol==99&&
                                                    <Button
                                                 color="warning" 
                                                 className="btn-sm" onClick={(evt)=>{this.enviar_correo(row.original.id,row.original.correo); }}>
                                                  <i className="nc-icon nc-email-85" />
                                                </Button>
                                                }
                                                <Button
                                                 color="success" 
                                                 className="btn-sm" onClick={(evt)=>{this.props.history.push('/app/reservaciones/editar/'+row.original.id); }}>
                                                    Editar
                                                </Button>

                                                 
                                                {this.props.auth.user.rol==99&&
                                                  <Button
                                                 color="info" 
                                                 className="btn-sm" onClick={(evt)=>{ swal.fire("Link de Stripe ", ' https://dashboard.stripe.com/payments/'+row.original.token_stripe, "info"); }}>
                                                    Stripe
                                                </Button>
                                                }
                                              
                                               
                                            </div>
                                        )
                                    }
                                }
                              ]}
                              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                              data      = {this.state.data}
                              pages={pages} // Display the total number of pages
                              loading={loading} // Display the loading overlay when we need it
                              onFetchData={_.debounce(this.fetchData, 500)} // Request new data when things change
                              filterable
                               ref={this.table2}
                              defaultPageSize={25} 
                              
                              
                             
    
                            />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
    return {
        user : state.user,
        auth:state.auth,
    }
};

export default connect(mapStateToProps, actions)(Users)
