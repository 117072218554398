import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/asesores';
import {api2,API_UR2,api } from '../../actions/_request';
import swal from 'sweetalert2';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  TabContent, TabPane, Nav, NavItem, NavLink, CardText
} from "reactstrap";
import Foto from '../../components/foto/index.js';
 import classnames from 'classnames'; 
 import ReactTable from "react-table";
import "react-table/react-table.css";
import Select from 'react-select';
import moment from 'moment';
const filterCaseInsensitive = (filter, row) => {
            const id = filter.pivotId || filter.id;
            if (row[id] !== null){
                return (
                    row[id] !== undefined ?
                        String((row[id]+'').toLowerCase()).includes(filter.value.toLowerCase())
                        :
                        true
                );
            }
        };
class User extends Component {
  constructor(props){
    super(props);

    this.state = {
      
      usuario:{
        id : null,
        area_id:null,
        fecha:'',
        hora_inicio:null,
        hora_final:null,
      },
      areas:[],
     
      action : 'save',
      submit:false,
      activeTab:"1",
     

    }
      this.handeInputChange = this.handeInputChange.bind(this);
     
      this.handleSubmit = this.handleSubmit.bind(this);
  }


  RetornoFoto=(foto)=>{
  let {usuario} = this.state;
  usuario.imagen = foto;
  // usuario.ext=foto.ext
  this.setState({
    usuario : usuario
  });

  }

  toggle_tab=(tab)=> {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
      }

  componentDidMount(){
    

    let _self = this;
    let {usuario} = this.state;
    let {params} = this.props.match;

    api2().get("/getareas_select")
      .then(function(response){
        if(response.status === 200){
          let areas= response.data.areas;
         
          _self.setState({
            areas : areas,
          }, () => { 
            if(params.id) {
              api2().get("/getbloqueoid/"+params.id)
              .then(function(response){
                if(response.status === 200){
                  let bloqueo = response.data.bloqueo;

                  bloqueo.fecha=moment(bloqueo.fecha).format('YYYY-MM-DD');
                  console.log('bloqueo--->',bloqueo)
                  _self.setState({
                    usuario : bloqueo,
                    
                  })
                }

              });
            }
           })
        }

      });


  }
    handleSelectChange=(select, name)=> {
      
        
        const value = select === null ? null : select.value;
      
             this.setState({
                
                usuario: {
                    ...this.state.usuario,
                    [name]: value
                }
            }, () => {
         
        });
        
    }

  handleSubmit(evt){

    evt.preventDefault();
    let _self = this;
    let {usuario} = this.state;
    _self.setState({
            submit : true
          })


    let actual = moment(moment().format('MM/DD/YYYY'));
    let fecha=moment(usuario.fecha);

    let inicio=moment('2021-11-12 '+ usuario.hora_inicio);
    let final=moment('2021-11-12 '+ usuario.hora_final);


 
    console.log('actual---->',actual);
    if (fecha<actual) {
        swal.fire("Alerta!", 'Favor de Revisar la Fecha de Bloqueo', "warning");
           _self.setState({
                        submit : false
                      });
           return
    }



     if (inicio>final) {
           swal.fire("Alerta!", 'Favor de Revisar El Horario de Bloqueo', "warning");
           _self.setState({
                        submit : false
                      });
           return
        }

    
     

    
    if (usuario.area_id==null) {
           swal.fire("Alerta!", 'Favor de Seleccionar una Area', "warning");
           _self.setState({
                        submit : false
                      });
        }else{



          api().post(`/created_updated_bloqueos`,usuario)
        .then(function(response)
        {
           if(response.status){
                  if(response.data.estado){
                    swal.fire("Bien hecho!", "La Informacion fue Guardada con éxito!", "success");
                    _self.props.history.push('/app/bloqueos');
                  }else{
                    swal.fire("Alerta!", response.data.mensaje, "warning");
                    _self.setState({
                        submit : false
                      });
                  }
                }else{
                  // console.log('error')
                  swal.fire({
                            type: 'error',
                            title: 'Solicitar ayuda a un Administrador',
                          })
                  _self.setState({
                        submit : false
                      });
                }
        }).catch(() => {
          swal.fire("Alerta!", 'Solicitar ayuda a un Administrador', "warning");
         
            })



            
        }
  }



  handeInputChange(event){
    let {usuario} = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    usuario[name] = value;
   
    this.setState({
        usuario : usuario
    });
  }







 

  

    regresar=(evt)=>
    {
      evt.preventDefault();
      
      this.props.history.push('/app/bloqueos');
    }
  render() {
     let {usuario,submit,experiencia,educacion} = this.state;
     // console.log('state---->',this.state)
    return (
      
        <div className="content">
          <Row className='justify-content-center'>
          <Col  md="1"></Col>
            <Col md="5">
              <Card className="card-user">
                <CardHeader>
                  <CardTitle tag="h5">Bloqueo</CardTitle>
                </CardHeader>
                <CardBody>


                <Row>
                <Col>
            
                    <TabContent activeTab={this.state.activeTab}>
                    
                    
                      <TabPane tabId="1">
                      <Form onSubmit={this.handleSubmit}>
                    <Row className='pt-4'>
                    
                      <div className="col-md-12"> 
                    <div className="form-group">
                        <label >Area *:</label>
                        <Select
                            placeholder=""
                            type="text"
                            name="area_id"
                            options={this.state.areas}
                             value={this.state.areas.filter(option => option.value == usuario.area_id)}
                            onChange={ (select) => { this.handleSelectChange(select, 'area_id') } }
                            clearable={false}

                        />
                    </div>
                </div>
                     
                       <Col md="12">
                       <Row>
                         <Col className="pr-1" md="12">
                        <FormGroup>
                          <label>Fecha</label>
                          <Input
                            placeholder="Fecha"
                            type="date"
                            name="fecha"
                            required
                            value={usuario.fecha}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>
                      
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Hora Inicio</label>
                          <Input
                            placeholder=""
                            type="time"
                            name="hora_inicio"
                            required
                            value={usuario.hora_inicio}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>
                       <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Hora Final</label>
                          <Input
                            placeholder=""
                            type="time"
                            name="hora_final"
                            required
                            value={usuario.hora_final}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>

                       </Row> 
                      </Col>
                       <Col md="4">
                       
                      </Col>
                      
                    </Row>
                    <Row>
                      
                      

                      
                      

                      

                     
                      
                    </Row>
                  
                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                          disabled={submit}
                        >
                          Guardar
                        </Button>
                        <Button
                          className="btn-round"
                          color="warning"
                          onClick={this.regresar}>
                          Regresar
                        </Button>
                      </div>
                    </Row>
                  </Form>
                       </TabPane>                      
                    </TabContent>
                </Col>
                  
                </Row>

                  
                </CardBody>
              </Card>
            </Col>
          </Row>
          
          
        </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
    return {
        auth : state.auth,
    }
};

export default connect(mapStateToProps, actions)(User)

