import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import "react-table/react-table.css";

import {API_URL} from '../../actions/_request';
import {api,api2} from '../../actions/_request';
import _ from "lodash";

import * as actions from '../../actions/user';
import swal from 'sweetalert2';
import moment from 'moment';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,Badge, Table 
} from "reactstrap";

class Users2 extends Component {
  render() {
    return (
        <div className="content">
          <Row>
            <Col md="12">
              <Card className="card-user">
                <CardBody>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
    );
  }
}

const requestData = (pageSize, page, sorted, filtered) => {    
    return new Promise((resolve, reject) => {
        // You can retrieve your data however you want, in this case, we will just use some local data.
        let filteredData ;
       
        var hora_final='';
        var area='';
        var fecha='';
        var hora_inicio='';
        var take= pageSize;
        var skip=pageSize * page;
        var total=1; 
        var patt = new RegExp("(si|s)", "i"); 
        for (var i = filtered.length - 1; i >= 0; i--) {
          switch(filtered[i].id)
          {
                       
            case "hora_final":
              hora_final=filtered[i].value;
              // console.log('hora_final--->',filtered[i].value);
              break;
            case "area":
              area=filtered[i].value;
              // console.log('empleado--->',filtered[i].value);
              break; 
              case "fechax":
              fecha=filtered[i].value;
              // console.log('empleado--->',filtered[i].value);
              break; 
            
          case "hora_inicio":
              hora_inicio=filtered[i].value;
              // console.log('rfc--->',filtered[i].value);
              break; 
          }
        }
              
              api2().get(`/getbloqueos?area=${area}&fecha=${fecha}&hora_inicio=${hora_inicio}&hora_final=${hora_final}&take=${take}&skip=${skip}`)
              .then(function(response)
              {
                if(response.status === 200)
                {
                  filteredData= response.data.datos;
                  total= response.data.total; 
                  // You can also use the sorting in your request, but again, you are responsible for applying it.
                  const sortedData = _.orderBy(
                    filteredData,
                    sorted.map(sort => {
                      return row => {
                        if (row[sort.id] === null || row[sort.id] === undefined) {
                          return -Infinity;
                        }
                        return typeof row[sort.id] === "string"
                        ? row[sort.id].toLowerCase()
                        : row[sort.id];
                      };
                    }),
                    sorted.map(d => (d.desc ? "desc" : "asc"))
                  );
                  const res = {
                    rows: sortedData,
                    pages: Math.ceil(total / pageSize)
                  };
                  // Here we'll simulate a server response with 500ms of delay.
                  //setTimeout(() => resolve(res), 1000);
                  //console.log(res);
                  resolve(res)
                }
              });   
        
    });
};



class Users extends Component {

  constructor(props) {

        super(props);

        this.state = {
             pages: null,
             loading: true,
        };

        this.fetchData = this.fetchData.bind(this);
         this.table = React.createRef();

    }

    componentDidMount(){
    

    console.log('tabla-->',this.table.current.state)
    this.table.current.state.filtered.push( {id: "fecha", value:'' });
    this.fetchData(this.table.current.state);

  }


  delete=(id,data)=>{
        

        // console.log('Factura------>',factura)
        let fecha=moment(data.fecha).format('MM/DD/YYYY');
      let self = this;
      swal.queue([{
        title: 'Favor de Confirmar ',
        text: ` Se Eliminara el bloqueo del area ${data.area} del dia ${fecha} entre las ${moment(fecha+' '+ data.hora_inicio).format('hh:mm:ss A')} y  ${moment(fecha+' '+ data.hora_final).format('hh:mm:ss A')}`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return api().get(`/delete_bloqueo/${id}`)
        .then(function(response)
        {
          if(response.status === 200)
          {
               swal.insertQueueStep({
                        type: 'success',
                        title: 'Se elimino correctamente'
                      })
                  self.fetchData(self.table.current.state);
           
          }
        }).catch(() => {
              swal.insertQueueStep({
                type: 'error',
                title: 'Solicitar ayuda a un Administrador',
              })
            })
        }
      }])


        
    }


     fetchData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false
      });
      // console.log('datos--->',res.rows);
       // this.props.get(res.rows);
    });
  }


  render() {
    const {  pages, loading} = this.state;
    return (
      <div className="content">
        <Row>
          <Col >
            <Card>
              <CardHeader>
              <Row>
              <Col xs={6}>
            
              </Col>
              <Col className='text-right' xs={6}>
              <Link to="/app/bloqueos/nuevo">
                <Button color="primary" className="mb-2" >
                    Agregar
                </Button>
              </Link>
              </Col>
              </Row>
              </CardHeader>
              <CardBody>
              <ReactTable
                             getTrProps={(state, rowInfo, column) => {
                                 
                                  if(rowInfo === undefined){
                                         
                                          return{

                                          }
                                        }else{
                                           return{
                                            onDoubleClick: (e) => {
                                              
                                                  this.props.history.push('/app/bloqueos/editar/'+rowInfo.original.id);
                                             
                                              
                                            }
                                        } 
                                  
                                    }
                              }}
                              
                              
                              className = "-striped -highlight"
                              columns   = {[
                               
                                {Header: 'Area', accessor: 'area'},
                                {Header: 'Fecha', accessor: 'fechax',minWidth:150,style: { textAlign: 'center'}, 
                                Cell: (row) =>
                                    {
                                        return(
                                            <div>{moment(row.original.fecha).format('MM/DD/YYYY')}</div>
                                        )
                                    },
                                     Filter: ({ filter, onChange }) =>
                                  <input
                                    type="date" 
                                    name="fechax"
                                    onChange={event => onChange(event.target.value)}
                                   value={filter ? filter.value : ""}
                                />
                                  } ,
                                 {Header: 'Inicio', accessor: 'hora_inicio',minWidth:150,style: { textAlign: 'center'} , Filter: ({ filter, onChange }) =>
                                  <input
                                    type="time" 
                                    name="hora_inicio"
                                    onChange={event => onChange(event.target.value)}
                                   value={filter ? filter.value : ""}
                                />,Cell: (row) =>
                                    {
                                        return(
                                            <div>{moment('2021-11-12 '+ row.original.hora_inicio).format('hh:mm:ss A')}</div>
                                        )
                                    },},
                                  {Header: 'Final', accessor: 'hora_final',minWidth:150,style: { textAlign: 'center'} , Filter: ({ filter, onChange }) =>
                                  <input
                                    type="time" 
                                    name="hora_final"
                                    onChange={event => onChange(event.target.value)}
                                   value={filter ? filter.value : ""}
                                />,Cell: (row) =>
                                    {
                                        return(
                                            <div>{moment('2021-11-12 '+ row.original.hora_final).format('hh:mm:ss A')}</div>
                                        )
                                    },},
                               
                                {
                                    Header: 'Controles',
                                    filterable: false,
                                    sortable: false,
                                     maxWidth:250,
                                    Cell: (row) =>
                                    {
                                        return(
                                            <div className="text-center">
                                                <Button
                                                 color="success" 
                                                 className="btn-sm" onClick={(evt)=>{this.props.history.push('/app/bloqueos/editar/'+row.original.id); }}>
                                                    Editar
                                                </Button>
                                                 <Button
                                                 color="danger" 
                                                 className="btn-sm" onClick={(evt)=>{this.delete(row.original.id,row.original); }}>
                                                    Eliminar
                                                </Button>
                                                
                                              
                                            </div>
                                        )
                                    }
                                }
                              ]}
                              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                              data      = {this.state.data}
                              pages={pages} // Display the total number of pages
                              loading={loading} // Display the loading overlay when we need it
                              onFetchData={_.debounce(this.fetchData, 500)} // Request new data when things change
                              filterable
                               ref={this.table}
                              defaultPageSize={25} 
                              
                              
                             
    
                            />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
    return {
        user : state.user,
        auth:state.auth,
    }
};

export default connect(mapStateToProps, actions)(Users)
