import React from 'react';
import {connect} from 'react-redux';
import {Button, 
		Modal, 
		ModalHeader, 
		ModalBody, 
		ModalFooter,
		Label, 
		Input,
		FormGroup} from 'reactstrap';
import spinner from 'react-spinkit';

import swal from 'sweetalert2';

import * as actions from '../../actions/user';
import {api2,API_UR2,api,api_descarga } from '../../actions/_request';
import Spinner from 'react-spinkit';
import moment from 'moment';
import Select from 'react-select';

const SubmitLoad = () => (
    <div className="p-2">
        <Spinner name="ball-clip-rotate-multiple" color="#b5271f" />
    </div>
);

class Excelreservaciones extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			
			estado:159648,
			area_id:0,
			areas:[],
			estados:this.props.auth.estados_reservacion,
			fecha_inicio:moment().format('Y-MM-DD'),
			fecha_final:moment().format('Y-MM-DD')
		}

		this.handleSubmit           = this.handleSubmit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}


	 componentDidMount(){
    

    let _self = this;
    let {estados} = this.state;
    
	estados.push( {label: "Todos", value: 159648});


	 api2().get("/getareas_select")
            .then(function(response){
              if(response.status === 200){
                let areas = response.data.areas;
                areas.push( {label: "Todas", value: 0});
               	_self.setState({
	            'estados': estados,
	            'areas':areas
	        });
                }

            });






  }

	handleSelectChange=(select, name )=> {
     
     let _self = this;
      const value = select === null ? null : select.value;

       this.setState({
            [name]: value,
        });
     
    }

	handleInputChange(event) {
        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;
  		
        	
        this.setState({
            [name]: value,
        });

        
        
    	
    }


	handleSubmit(evt)
    {
    	evt.preventDefault();
    	let {estado,area_id,fecha_inicio,fecha_final} = this.state;

    	if(estado==159648)
    		estado='';
    	if(area_id==0)
    		area_id='';
    	if(fecha_final=='')
		{
			swal.fire("Alerta!", 'Favor de Seleccionar una Fecha Final', "warning");
			return
		}
		if(fecha_inicio=='')
		{
			swal.fire("Alerta!", 'Favor de Seleccionar una Fecha Inicial', "warning");
			return
		}
		let area_nombre='';
		if(area_id!=0)
    		area_nombre= " del Area "+this.state.areas.filter(option => option.value == area_id)[0].label

    	console.log('dfasf-->',this.state.areas.filter(option => option.value == area_id))

    	 api_descarga().post(`/get_csv?estado=${estado}&area_id=${area_id}&fecha_inicio=${fecha_inicio}&fecha_final=${fecha_final}`)
            .then(function(response){
              if(response.status === 200){
                  var a = document.createElement("a");
                  a.href = URL.createObjectURL(new Blob([response.data])) ; 
                  a.download = `Reservaciones del ${fecha_inicio} al  ${fecha_final} ${area_nombre}.csv`;
                  document.body.appendChild(a);
                  a.click();
                  a.remove();
               
                }

            });



    	
  
    }

	render(){
		// console.log('User------>',this.props.auth)
		return(
			<Modal isOpen={this.props.open} toggle={this.props.toggle} className="default" >
	        	<form onSubmit={this.handleSubmit}  >
		            <ModalHeader toggle={this.props.toggle}>Excel Reservaciones</ModalHeader>
		            <ModalBody >
	                	<div className="row">
	                	 <div className="col-md-6"> 
		                    <div className="form-group">
		                        <label >Fecha Inicio *:</label>
		                        <input type="date" required  onChange={ this.handleInputChange} className="form-control"   name="fecha_inicio" value={this.state.fecha_inicio} />
		                    </div>
		                </div>
		                <div className="col-md-6"> 
		                    <div className="form-group">
		                        <label >Fecha Final *:</label>
		                        <input type="date" required  onChange={ this.handleInputChange} className="form-control"   name="fecha_final" value={this.state.fecha_final} />
		                    </div>
		                </div>
		                <div className="col-12 col-md-6 text-center"> 
                        <div className="form-group">
                          <label className='col-form-label'>Estado:</label>
                          <Select  placeholder="" type="text"   name="estado" options={this.state.estados}  value={this.state.estados.filter(option => option.value == this.state.estado)}  onChange={ (select) => { this.handleSelectChange(select, 'estado') } } clearable={false} />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 text-center"> 
                        <div className="form-group">
                          <label className='col-form-label'>Area:</label>
                          <Select  placeholder="" type="text"   name="area_id" options={this.state.areas}  value={this.state.areas.filter(option => option.value == this.state.area_id)}  onChange={ (select) => { this.handleSelectChange(select, 'area_id') } } clearable={false} />
                        </div>
                    </div>
	                		
	                    </div>
		            </ModalBody>
		            <ModalFooter>
		                {
		                    <div>
		                        <Button color="primary" type="submit" >
		                           Guardar
		                        </Button>{' '}
		                        <Button color="secondary" onClick={this.props.toggle}>
		                            Cancelar
		                        </Button>
		                    </div>
		                }
		            </ModalFooter>
	        	</form>
        	</Modal>
		)
	}
}

function mapStatetoProps(state, ownProps) {
	return {
		user : state.user,
		auth : state.auth,
	}
}

export default connect(mapStatetoProps, actions)(Excelreservaciones)