import React from 'react';
import { connect } from 'react-redux';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,Label,FormGroup, Input,InputGroup, InputGroupAddon,Card, CardBlock
} from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink,   CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';

import Spinner from 'react-spinkit';

import swal from 'sweetalert2'
import ReactTable from "react-table";
import "react-table/react-table.css";



import * as actions from '../../actions/user';

import Select from 'react-select';

import {api} from '../../actions/_request';


const SubmitLoad = () => (
    <div className="p-2">
        <Spinner name="ball-clip-rotate-multiple" color="#b5271f" />
    </div>
);



class PaquetesModal extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            impuesto: {
                 id:null,               
                nombre_esp:'',
                nombre_ing:'',
                cantidad:'',
                precio:'',
                estado:0,
                tipo:1        
            },
            estados:[{label:'Activado',value:1},{label:'Desactivado',value:0}],
            tipos:[{label:'Personas',value:1},{label:'Mesas',value:2}],
            action: 'save',
            activeTab: '1',
            modal:false,
            usuarios:[],
        };


                 
    }
   
    handleSelectChange=(select, name)=> {
      
        
        const value = select === null ? null : select.value;
      
             this.setState({
                
                impuesto: {
                    ...this.state.impuesto,
                    [name]: value
                }
            }, () => {
         
        });
        
    }
  

    componentDidMount()
    {
        /*tenemos que verificar si  ya se cuanta con un id para  pedir la informacion*/
         // console.log('Paquete id --->',this.props.paquete_id)
         let _self=this;
        if(this.props.paquete_id) {

              api().get("/getpaquete/"+this.props.paquete_id)
              .then(function(response){
                if(response.status === 200){
                  let paquete = response.data.paquete;

                
                  
                  _self.setState({
                    impuesto : paquete,
                  
                    
                  })
                }

              });
            }
              
       
    }

   

    resetModal=()=>{
        this.setState({
            impuesto: {
                id:null,
                nombre_esp:'',
                nombre_ing:'',
                cantidad:'',
                precio:'',
                estado:1,
                tipo:1,
                area_id:0,

                  
            },
            tipos:[{label:'Personas',value:1},{label:'Mesas',value:2}],
            action: 'save',
             activeTab: '1',
        });
        
    }

    



    handleInputChange=(event)=> {
        let {impuesto} = this.state;
        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;

        impuesto[name] = value;
     
        this.setState({
            impuesto : impuesto
        });
    }

   
   
   

    handleSubmit=(evt)=>
    {
        let {impuesto} = this.state;
        evt.preventDefault();
         let _self=this;
        /*enviamos los datos a guardar*/
        impuesto.area_id=this.props.area_id;
         _self.setState({
                        saving : true,
                      });

      api().post(`/created_updated_paquetes`,impuesto)
        .then(function(response)
        {
           if(response.status){
                  if(response.data.estado){
                    swal.fire("Bien hecho!", "La Informacion fue Guardada con éxito!", "success");
                    // _self.props.history.push('/app/bloqueos');

                        
                     _self.setState({
                        saving : false,
                     
                      });
                  }else{


                    swal.fire("Alerta!", response.data.mensaje, "warning");
                    _self.setState({
                        saving : false,
                      
                      });
                  }
                }else{
                  // console.log('error')
                  swal.fire({
                            type: 'error',
                            title: 'Solicitar ayuda a un Administrador',
                          })
                  _self.setState({
                        saving : false
                      });
                }
        }).catch(() => {
          swal.fire("Alerta!", 'Solicitar ayuda a un Administrador', "warning");
         
            })

        







          this.props.retorno_paquete()

        


        

      

    }

    render()
    {

        let {impuesto}=this.state;
  
        
        return(

           <Modal isOpen={this.props.open} toggle={this.props.toggle} className="default modal-lg modal-80 modal_cliente">
                <form >
                <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>
                <ModalBody>
                     
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                          <div className="row mt-3 form_cliente">
                        <div className="col-md-2"> 
                    <div className="form-group">
                        <label >Estado *:</label>
                        <Select
                            placeholder=""
                            type="text"
                            name="duracion"
                            isDisabled
                            options={this.state.estados}
                             value={this.state.estados.filter(option => option.value == impuesto.estado)}
                            onChange={ (select) => { this.handleSelectChange(select, 'estado') } }
                            clearable={false}

                        />
                    </div>
                </div>
                        <div className="col-sm-2"> 
                                    <div className="form-group">
                                        <label className>Tipo:</label>
                                        <Select
                                            type="text"
                                            name="tipo"
                                            options={this.state.tipos}
                                         
                                             value={this.state.tipos.filter(option => option.value == impuesto.tipo)}
                                            onChange={ (select) => { this.handleSelectChange(select, 'tipo') } }
                                            clearable={false}
                                        />
                                    </div>
                                </div>   
                        <div className="col-md-2">
                            <FormGroup>
                                <Label>Cantidad</Label>
                                    <Input
                                    placeholder=""
                                    type="number"
                                    name="cantidad"
                                    maxLength={99}
                                    value={impuesto.cantidad}
                                    required
                                    onChange={ this.handleInputChange}
                                    />
                            </FormGroup>
                        </div>
                        <div className="col-md-2">
                            <FormGroup>
                                <Label>Precio</Label>
                                    <Input
                                    placeholder=""
                                    type="number"
                                    name="precio"
                                    step='0.01'
                                    required
                                    value={impuesto.precio}
                                    onChange={ this.handleInputChange}
                                    />
                            </FormGroup>
                        </div>                     
                        </div>
                         <div className="row mt-3 ">       
                        <div className="col-md-6">
                            <FormGroup>
                                <Label>Descripción Español</Label>
                                    <Input
                                    placeholder=""
                                    type="text"
                                    required
                                    name="nombre_esp"
                                    maxLength={99}
                                    value={impuesto.nombre_esp}
                                    onChange={ this.handleInputChange}
                                    />
                            </FormGroup>
                        </div>
                        <div className="col-md-6">
                            <FormGroup>
                                <Label>Descripción Ingles</Label>
                                    <Input
                                    placeholder=""
                                    required
                                    type="text"
                                    name="nombre_ing"
                                    maxLength={99}
                                    value={impuesto.nombre_ing}
                                    onChange={ this.handleInputChange}
                                    />
                            </FormGroup>
                        </div>
                        
                                         
                    </div>
                           
                          </Col>
                        </Row>
                      </TabPane>
                                         
                    </TabContent>
                    
                </ModalBody>
                <ModalFooter>
                    {
                        this.state.saving ?
                        <SubmitLoad /> : 
                        <div>
                            <Button color="primary" type="submit"  onClick={this.handleSubmit} >
                                &nbsp;Guardar
                            </Button>{' '}
                            <Button color="secondary" onClick={this.props.toggle}>
                                Regresar
                            </Button>
                        </div>
                    }
                </ModalFooter>
                </form>
            </Modal>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        
        auth:state.auth
    }
};

export default connect(mapStateToProps, actions)(PaquetesModal)