import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import "react-table/react-table.css";

import {API_URL} from '../../actions/_request';
import {api,api2,API_UR2} from '../../actions/_request';
import _ from "lodash";

import * as actions from '../../actions/user';
import swal from 'sweetalert2';
import moment from 'moment';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,Badge, Table 
} from "reactstrap";

class Users2 extends Component {
  render() {
    return (
        <div className="content">
          <Row>
            <Col md="12">
              <Card className="card-user">
                <CardBody>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
    );
  }
}

const requestData = (pageSize, page, sorted, filtered) => {    
    return new Promise((resolve, reject) => {
        // You can retrieve your data however you want, in this case, we will just use some local data.
        let filteredData ;
       
        var nombre_esp='';
        var nombre_ing='';
        var estado='';
        var hora_inicio='';
        var take= pageSize;
        var skip=pageSize * page;
        var total=1; 
        var patt = new RegExp("(si|s)", "i"); 
        for (var i = filtered.length - 1; i >= 0; i--) {
          switch(filtered[i].id)
          {
                       
            case "nombre_esp":
              nombre_esp=filtered[i].value;
              // console.log('nombre_esp--->',filtered[i].value);
              break;
            case "nombre_ing":
              nombre_ing=filtered[i].value;
              // console.log('empleado--->',filtered[i].value);
              break; 
              case "estado":
              estado=filtered[i].value;
              // console.log('empleado--->',filtered[i].value);
              break; 
            
          case "hora_inicio":
              hora_inicio=filtered[i].value;
              // console.log('rfc--->',filtered[i].value);
              break; 
          }
        }
              
              api2().get(`/getareas?nombre_ing=${nombre_ing}&estado=${estado}&hora_inicio=${hora_inicio}&nombre_esp=${nombre_esp}&take=${take}&skip=${skip}`)
              .then(function(response)
              {
                if(response.status === 200)
                {
                  filteredData= response.data.datos;
                  total= response.data.total; 
                  // You can also use the sorting in your request, but again, you are responsible for applying it.
                  const sortedData = _.orderBy(
                    filteredData,
                    sorted.map(sort => {
                      return row => {
                        if (row[sort.id] === null || row[sort.id] === undefined) {
                          return -Infinity;
                        }
                        return typeof row[sort.id] === "string"
                        ? row[sort.id].toLowerCase()
                        : row[sort.id];
                      };
                    }),
                    sorted.map(d => (d.desc ? "desc" : "asc"))
                  );
                  const res = {
                    rows: sortedData,
                    pages: Math.ceil(total / pageSize)
                  };
                  // Here we'll simulate a server response with 500ms of delay.
                  //setTimeout(() => resolve(res), 1000);
                  //console.log(res);
                  resolve(res)
                }
              });   
        
    });
};



class Users extends Component {

  constructor(props) {

        super(props);

        this.state = {
             pages: null,
             loading: true,
        };

        this.fetchData = this.fetchData.bind(this);
         this.table = React.createRef();

    }


     copiarAlPortapapeles=(id)=> {


      swal.fire({
  title: 'Seleccionar el idioma?',
 
  showCancelButton: true,
  confirmButtonText: 'Español',
  cancelButtonText: `Ingles`,
}).then((result) => {
  /* Read more about isConfirmed, isDenied below */
  // console.log('result--->',result)
  let idioma =2;
  if (result.dismiss=='cancel') {
    idioma=1;
  } else if (result.value) {
    idioma=0;
  }
// console.log('idioma--->',idioma)
  if(idioma<2){

     var inputFalso = document.createElement("input");
  inputFalso.setAttribute("value", `<iframe id="inlineFrameExample"
           width="100%" 
           height="100%" 
          frameBorder="0"
          allow="payment" 
         sandbox=" allow-forms allow-scripts allow-same-origin allow-popups  allow-forms"
          src="https://casafridavalle.com/reservaciones/?evento=${id}&idioma=${idioma}">
        </iframe>
        <script type="text/javascript">
              setInterval(function () {
                var theFrame=window.document.getElementById('inlineFrameExample');
                var iframeDocument = theFrame.contentWindow.document;
                var altura = iframeDocument.querySelector("html").offsetHeight;
                    theFrame.style.height=altura+'px';
              }, 300);
             
          
</script>`);

  document.body.appendChild(inputFalso);

  inputFalso.select();

  document.execCommand("copy");

  document.body.removeChild(inputFalso);

  swal.fire('Configuracion Copiada!', '', 'success')


  }
})


 

}


  cambiar_estado=(id,estado,estado_texto,data)=>{
        

        // console.log('Factura------>',factura)

      let self = this;
      swal.queue([{
        title: 'Favor de Confirmar ',
        text: ` Se Cambiara el Estado a ${estado_texto} al area ${data.nombre_esp} `,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return api().get(`/cambiar_estado_area/${id}/${estado}`)
        .then(function(response)
        {
          if(response.status === 200)
          {
               swal.insertQueueStep({
                        type: 'success',
                        title: 'Se Cambio el estado correctamente'
                      })
                  self.fetchData(self.table.current.state);
           
          }
        }).catch(() => {
              swal.insertQueueStep({
                type: 'error',
                title: 'Solicitar ayuda a un Administrador',
              })
            })
        }
      }])


        
    }


     fetchData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false
      });
      // console.log('datos--->',res.rows);
       // this.props.get(res.rows);
    });
  }


  render() {
    const {  pages, loading} = this.state;
    return (
      <div className="content">
        <Row>
          <Col >
            <Card>
              <CardHeader>
              <Row>
              <Col xs={6}>
            
              </Col>
              <Col className='text-right' xs={6}>
              <Link to="/app/areas/nuevo">
                <Button color="primary" className="mb-2" >
                    Agregar
                </Button>
              </Link>
              </Col>
              </Row>
              </CardHeader>
              <CardBody>
              <ReactTable
                             getTrProps={(state, rowInfo, column) => {
                                 
                                  if(rowInfo === undefined){
                                         
                                          return{

                                          }
                                        }else{
                                           return{
                                            onDoubleClick: (e) => {
                                              
                                                  this.props.history.push('/app/bloqueos/areas/'+rowInfo.original.id);
                                             
                                              
                                            }
                                        } 
                                  
                                    }
                              }}
                              
                              
                              className = "-striped -highlight"
                              columns   = {[
                               
                                
                                {Header: 'Estado', accessor: 'estado',minWidth:150,style: { textAlign: 'center'}, 
                                Cell: (row) =>
                                    {
                                        return(
                                            <div>{row.original.estado==1?'Activa':'Deshabilitada'}</div>
                                        )
                                    },
                                     Filter: ({ filter, onChange }) =>

                                     <select name="estado" id="estado" onChange={event => onChange(event.target.value)}
                                             value={filter ? filter.value : ""}>
                                      <option value="">Todas</option>
                                      <option value="1">Activa</option>
                                      <option value="0">Deshabilitada</option>
                                    
                                    </select>
                                 
                                  } ,
                                  {Header: 'Español', accessor: 'nombre_esp'},
                                  {Header: 'Ingles', accessor: 'nombre_ing'},
                                  {Header: 'Anticipacion',filterable: false,sortable: false, accessor: 'anticipacion',Cell: (row) =>
                                    {
                                        return(
                                            <div>{`${row.original.anticipacion} Dias`}</div>
                                        )
                                    },},
                                    {Header: 'Duracion',filterable: false,sortable: false, accessor: 'duracion',Cell: (row) =>
                                    {
                                        return(
                                            <div>{`${row.original.duracion} min`}</div>
                                        )
                                    },},
                                    {Header: 'Anticipo %',filterable: false,sortable: false, accessor: 'anticipacion',Cell: (row) =>
                                    {
                                        return(
                                            <div>{`${row.original.anticipo_pago}%`}</div>
                                        )
                                    },},
                              
                               
                                {
                                    Header: 'Controles',
                                    filterable: false,
                                    sortable: false,
                                     maxWidth:450,
                                      minWidth:150,
                                    Cell: (row) =>
                                    {
                                        return(
                                            <div className="text-center">
                                                <Button
                                                 color="success" 
                                                 className="btn-sm" onClick={(evt)=>{this.props.history.push('/app/areas/editar/'+row.original.id); }}>
                                                    Editar
                                                </Button>
                                                { row.original.estado==1?
                                                 <Button
                                                 color="danger" 
                                                 className="btn-sm" onClick={(evt)=>{this.cambiar_estado(row.original.id,0,'Deshabilitada',row.original); }}>
                                                    Deshabilitar
                                                </Button>
                                                :
                                                <Button
                                                 color="primary" 
                                                 className="btn-sm" onClick={(evt)=>{this.cambiar_estado(row.original.id,1,'Activvar',row.original); }}>
                                                    Activar
                                                </Button>
                                                }
                                                { row.original.estado==1&&
                                                 <Button
                                                 color="danger" 
                                                 className="btn-sm" onClick={(evt)=>{this.copiarAlPortapapeles(row.original.id,0,'Deshabilitada',row.original); }}>
                                                    <i className="nc-icon nc-settings-gear-65" />
                                                </Button>
                                               
                                                }



                                              
                                            </div>
                                        )
                                    }
                                }
                              ]}
                              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                              data      = {this.state.data}
                              pages={pages} // Display the total number of pages
                              loading={loading} // Display the loading overlay when we need it
                              onFetchData={_.debounce(this.fetchData, 500)} // Request new data when things change
                              filterable
                               ref={this.table}
                              defaultPageSize={25} 
                              
                              
                             
                            />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
    return {
        user : state.user,
        auth:state.auth,
    }
};

export default connect(mapStateToProps, actions)(Users)
