import React from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import logo from "logo.svg";

var ps;



class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.sidebar = React.createRef();

    this.state = {
     
      activeTab: '',
      modal_categorias:false,
      modal_datos:false,
      

      
    }

     this.abrir= this.abrir.bind(this);
     this.toggle_modal= this.toggle_modal.bind(this);
  }


  toggle_modal(modal) {
    let name = this.state[modal];

    this.setState({
      [modal]: !name
    });

  }


  abrir(menu){
      var activeTab='';
      if ( parseInt( this.state.activeTab ) !== parseInt(menu) ) {
       activeTab= menu;
       
      }

      this.setState({
          activeTab: activeTab
        });

       
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    // console.log('state----->',this.state)

    


    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >
        <div className="logo">
          <a
            href="http://www.wenatives.com" target="_blank"
            className="simple-text logo-mini"
          >
            <div className="logo-img">
              <img src={logo} alt="react-logo" />
            </div>
          </a>
          <a
            href="http://www.wenatives.com" target="_blank"
            className="simple-text logo-normal"
          >
            WeNatives
          </a>
        </div>
        <div className="sidebar-wrapper" ref={this.sidebar}>
          <Nav>
>
              <li className={this.activeRoute('/Dashboard') } >
                  <NavLink
                    to={'/app/Dashboard'}
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className={'nc-icon nc-single-copy-04'} />
                    <p>Dashboard</p>
                  </NavLink>
                </li>
                 
             
            {this.props.routes.map((prop, key) => {
              return (
                <li
                  className={
                    this.activeRoute(prop.layout + prop.path) +
                    (prop.pro ? " active-pro" : "")
                  }
                  key={key}
                >
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
              );
            })}
          </Nav>
        </div>
       
      </div>
    );
  }
}

export default Sidebar;
