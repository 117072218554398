import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/asesores';
import {api2,API_UR2,api } from '../../actions/_request';
import swal from 'sweetalert2';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  TabContent, TabPane, Nav, NavItem, NavLink, CardText
} from "reactstrap";
import Foto from '../../components/foto/index.js';
 import classnames from 'classnames'; 
 import ReactTable from "react-table";
import "react-table/react-table.css";
const filterCaseInsensitive = (filter, row) => {
            const id = filter.pivotId || filter.id;
            if (row[id] !== null){
                return (
                    row[id] !== undefined ?
                        String((row[id]+'').toLowerCase()).includes(filter.value.toLowerCase())
                        :
                        true
                );
            }
        };
class User extends Component {
  constructor(props){
    super(props);

    this.state = {
      
      usuario:{
        id : null,
        name:'',
        email:'',
        telefono:'',
        password:'',
        rol:1
    
    
      },
     
      action : 'save',
      submit:false,
      activeTab:"1",
     

    }
      this.handeInputChange = this.handeInputChange.bind(this);
     
      this.handleSubmit = this.handleSubmit.bind(this);
  }


  RetornoFoto=(foto)=>{
  let {usuario} = this.state;
  usuario.imagen = foto;
  // usuario.ext=foto.ext
  this.setState({
    usuario : usuario
  });

  }

  toggle_tab=(tab)=> {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
      }

  componentDidMount(){
    

    let _self = this;
    let {usuario} = this.state;
    let {params} = this.props.match;
    if(params.id) {
      api2().get("/getUserByid/"+params.id)
      .then(function(response){
        if(response.status === 200){
          usuario = response.data.asesor;
          _self.setState({
            usuario : usuario,
          })
        }

      });
    }

  }
   handleSelectChange=(select, name )=> {
      let {usuario} = this.state;
      let value=null;
      value = select === null ? null : select.target.value;
      usuario[name] = value;
      // console.log('value---->', value);
      // console.log('usuario---->',usuario);
      // console.log('name---->',name);
      this.setState({usuario : usuario});
    }


  handleSubmit(evt){

    evt.preventDefault();
    let _self = this;
    let {usuario} = this.state;
    var patt = new RegExp("[a-zA-Z0-9]?[a-zA-Z0-9]{11}[a-zA-Z0-9]$", "i");
     _self.setState({
            submit : true
          })

    let emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!emailRegex.test(usuario.email)) {
           swal.fire("Alerta!", 'Correo No valido', "warning");
           _self.setState({
                        submit : false
                      });
        }else{



          api().post(`/created_updated_users`,usuario)
        .then(function(response)
        {
           if(response.status){
                  if(response.data.estado){
                    swal.fire("Bien hecho!", "La Informacion fue Guardada con éxito!", "success");
                    _self.props.history.push('/app/usuarios');
                  }else{
                    swal.fire("Alerta!", response.data.mensaje, "warning");
                    _self.setState({
                        submit : false
                      });
                  }
                }else{
                  console.log('error')
                  swal.fire({
                            type: 'error',
                            title: 'Solicitar ayuda a un Administrador',
                          })
                  _self.setState({
                        submit : false
                      });
                }
        }).catch(() => {
          swal.fire("Alerta!", 'Solicitar ayuda a un Administrador', "warning");
         
            })



            
        }
  }



  handeInputChange(event){
    let {usuario} = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    usuario[name] = value;
   
    this.setState({
        usuario : usuario
    });
  }







 

  

    regresar=(evt)=>
    {
      evt.preventDefault();
      
      this.props.history.push('/app/usuarios');
    }
  render() {
     let {usuario,submit,experiencia,educacion} = this.state;
     // console.log('state---->',this.state)
    return (
      
        <div className="content">
          <Row className='justify-content-center'>
          <Col  md="1"></Col>
            <Col md="5">
              <Card className="card-user">
                <CardHeader>
                  <CardTitle tag="h5">Perfil</CardTitle>
                </CardHeader>
                <CardBody>


                <Row>
                <Col>
            
                    <TabContent activeTab={this.state.activeTab}>
                    
                    
                      <TabPane tabId="1">
                      <Form onSubmit={this.handleSubmit}>
                    <Row className='pt-4'>
                    <Col className="pr-1" md="12">
                        <FormGroup>
                          <label>Rol</label>
                          <select class="form-control" id="exampleFormControlSelect1"
                               value={usuario.rol} 
                               name='rol'
                               onChange={(e) => this.handleSelectChange(e,'rol')}>
                            <option value={99}>Administrador</option>
                            <option value={1}>Solo Ver</option>
                           
                          </select>
                        </FormGroup>
                      </Col>
                     
                       <Col md="12">
                       <Row>
                         <Col className="pr-1" md="12">
                        <FormGroup>
                          <label>Nombre</label>
                          <Input
                            placeholder="Nombre Completo"
                            type="text"
                            name="name"
                            required
                            value={usuario.name}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>
                      
                      <Col className="pr-1" md="12">
                        <FormGroup>
                          <label>Correo</label>
                          <Input
                            placeholder=""
                            type="text"
                            name="email"
                            required
                            value={usuario.email}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>
                       </Row> 
                      </Col>
                       <Col md="4">
                       
                      </Col>
                      
                    </Row>
                    <Row>
                      
                       <Col className="pr-1" md="12">
                        <FormGroup>
                          <label>Telefono</label>
                          <Input
                            placeholder=""
                            type="text"
                            name="telefono"
                            required
                            value={usuario.telefono}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>


                      {usuario.id==null&&
                        <Col className="pr-1" md="12">
                        <FormGroup>
                          <label>Contraseña</label>
                          <Input
                            placeholder=""
                            type="text"
                            name="password"
                            required
                            value={usuario.password}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>
                     
                      }
                      

                      

                     
                      
                    </Row>
                  
                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                          disabled={submit}
                        >
                          Guardar
                        </Button>
                        <Button
                          className="btn-round"
                          color="warning"
                          onClick={this.regresar}>
                          Regresar
                        </Button>
                      </div>
                    </Row>
                  </Form>
                       </TabPane>                      
                    </TabContent>
                </Col>
                  
                </Row>

                  
                </CardBody>
              </Card>
            </Col>
          </Row>
          
          
        </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
    return {
        auth : state.auth,
    }
};

export default connect(mapStateToProps, actions)(User)

