/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.jsx";
import Ver from "views/verificacion";
import Notifications from "views/Notifications.jsx";
import Icons from "views/Icons.jsx";
import Typography from "views/Typography.jsx";
import TableList from "views/Tables.jsx";
import Maps from "views/Map.jsx";
import UserPage from "views/User.jsx";
import UpgradeToPro from "views/Upgrade.jsx";





import lista_usuarios from "views/usuarios/lista.js";
import formulario_usuarios from "views/usuarios/formulario.js";

import lista_bloqueos from "views/Bloqueos/lista.js";
import formulario_bloqueos from "views/Bloqueos/formulario.js";


import lista_areas from "views/areas/lista.js";
import formulario_areas from "views/areas/formulario.js";


import lista_reservaciones from "views/reservaciones/lista.js";
import formulario_reservaciones from "views/reservaciones/formulario.js";


var routes = [

  {
    path: "/Dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: lista_reservaciones,
    layout: "/app",
    exact: true,
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    icon: "nc-icon nc-single-02",
    component: lista_usuarios,
    layout: "/app",
    exact: true,
  },
  {
    path: "/usuarios/nuevo",
    name: "Usuario nuevo",
    icon: "nc-icon nc-single-02",
    component: formulario_usuarios,
    layout: "/app",
    exact: true
  },
  
  {
    path: "/usuarios/editar/:id",
    name: "Editar Usuario",
    icon: "nc-icon nc-single-02",
    component: formulario_usuarios,
    layout: "/app",
    exact: true
  },

  {
    path: "/bloqueos",
    name: "Bloqueos",
    icon: "nc-icon nc-single-02",
    component: lista_bloqueos,
    layout: "/app",
    exact: true,
  },
   {
    path: "/bloqueos/nuevo",
    name: "Bloqueo nuevo",
    icon: "nc-icon nc-single-02",
    component: formulario_bloqueos,
    layout: "/app",
    exact: true
  },
  
  {
    path: "/bloqueos/editar/:id",
    name: "Editar Bloqueo",
    icon: "nc-icon nc-single-02",
    component: formulario_bloqueos,
    layout: "/app",
    exact: true
  },

  {
    path: "/areas",
    name: "areas",
    icon: "nc-icon nc-single-02",
    component: lista_areas,
    layout: "/app",
    exact: true,
  },
   {
    path: "/areas/nuevo",
    name: "area nuevo",
    icon: "nc-icon nc-single-02",
    component: formulario_areas,
    layout: "/app",
    exact: true
  },
  
  {
    path: "/areas/editar/:id",
    name: "Editar Area",
    icon: "nc-icon nc-single-02",
    component: formulario_areas,
    layout: "/app",
    exact: true
  },


  {
    path: "/reservaciones",
    name: "Reservaciones",
    icon: "nc-icon nc-single-02",
    component: lista_reservaciones,
    layout: "/app",
    exact: true,
  },
   {
    path: "/reservaciones/nuevo",
    name: "Reservacion nueva",
    icon: "nc-icon nc-single-02",
    component: formulario_reservaciones,
    layout: "/app",
    exact: true
  },
  
  {
    path: "/reservaciones/editar/:id",
    name: "Editar Reservacion",
    icon: "nc-icon nc-single-02",
    component: formulario_reservaciones,
    layout: "/app",
    exact: true
  },


   






















  {
    path: "/icons",
    name: "Icons",
    icon: "nc-icon nc-diamond",
    component: Icons,
    layout: "/app"
  },
];



export default routes;
