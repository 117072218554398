import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/asesores';
import {api2,API_UR2,api } from '../../actions/_request';
import swal from 'sweetalert2';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  TabContent, TabPane, Nav, NavItem, NavLink, CardText
} from "reactstrap";
import Foto from '../../components/foto/index.js';
 import classnames from 'classnames'; 
 import ReactTable from "react-table";
import "react-table/react-table.css";
import moment from 'moment';
import Select from 'react-select';
const filterCaseInsensitive = (filter, row) => {
            const id = filter.pivotId || filter.id;
            if (row[id] !== null){
                return (
                    row[id] !== undefined ?
                        String((row[id]+'').toLowerCase()).includes(filter.value.toLowerCase())
                        :
                        true
                );
            }
        };
const estados = [
    {label: 'Reservada - No Pagada', value: 0},
    {label: 'Pagada', value: 1},
    {label: 'Cancelada', value: 99},
];
const idiomas = [
    {label: 'Español', value: 1},
    {label: 'Ingles', value: 2},
];

const identificaciones = [
    {label: 'INE', value: 'INE'},
    {label: 'Licencia', value: 'Licencia'},
    {label: 'Pasaporte', value: 'Pasaporte'},
    {label: 'ID Card', value: 'ID Card'},
    
];

const sino = [
    {label: 'Si', value: 1},
    {label: 'No', value: 0},
];
class User extends Component {
  constructor(props){
    super(props);

    this.state = {
      
      usuario:{
        id : null,
        area_id:0,
        paquete_id:0,
        cantidad:1,
        precio_unitario:0,
        total:0,
        nombre_paquete:'',
        fecha:moment().format('Y-MM-DD'),
        hora:'',
        duracion:0,
        anticipo:0,
        cliente:'',
        apellido:'',
        correo:'',
        identificacion:'INE',
        fecha_nacimiento:'',
        peticion_especial:'',
        fecha_registro:moment().format('Y-MM-DD'),
        idioma:1,
        aforo:1,
        bloqueo_id:0,
        estado:0,   
        telefono:'',
        nota_interna:''
      },
      areas:[],
      paquetes:[],
      horas:[],
      cantidades:[],
     
      action : 'save',
      submit:false,
      activeTab:"1",
     

    }
      this.handeInputChange = this.handeInputChange.bind(this);
     
      this.handleSubmit = this.handleSubmit.bind(this);
  }


  RetornoFoto=(foto)=>{
  let {usuario} = this.state;
  usuario.imagen = foto;
  // usuario.ext=foto.ext
  this.setState({
    usuario : usuario
  });

  }

  toggle_tab=(tab)=> {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
      }


get_cantidades=(cantidad)=>{
let cantidades=[];
let {usuario} = this.state
usuario.cantidad=1;
for (var i =cantidad ; i >= 1; i--) {
                     cantidades.push( {label: i, value: i});           
                    }
this.setState({
                  usuario:usuario,
                  cantidades:cantidades,
                })

}

get_paquetes=(area_id)=>{
  let _self = this;

let {usuario} = this.state
usuario.cantidad=1;
usuario.paquete_id=0;
  api2().get("/get_paquetes_area/"+area_id+'?filtro=1')
            .then(function(response){
              if(response.status === 200){
                let paquetes = response.data.paquetes;
                let cantidades=[];
                if(paquetes.length>0){
                   usuario.paquete_id=paquetes[0].value;
                   
                   usuario.precio_unitario=paquetes[0].precio;

                   usuario.total=paquetes[0].precio*usuario.cantidad;

                    let cantidad_max=paquetes[0].cantidad;
                   if(_self.state.areas.filter(option => option.value == usuario.area_id)[0].agrupar_reservaciones==1)
                      cantidad_max=_self.state.areas.filter(option => option.value == usuario.area_id)[0].cantidad_max;

                    for (var i = cantidad_max ; i >= 1; i--) {
                     cantidades.push( {label: i, value: i});           
                    }
                }
                _self.setState({
                  paquetes : paquetes,
                  usuario:usuario,
                  cantidades:cantidades,
                   horas : []
                })
              }

            });

}


 get_area_paquetes_inicio=(area=null ,paquete=null,precio=null,registro=null)=>{
let _self = this;

let {usuario} = this.state;
  api2().get("/getareas_select?filtro=1")
      .then(function(response){
        if(response.status === 200){
          let areas=response.data.areas;
          if(areas.length>0){
            
            if(area!=null){
              
              
               /*revisamos que el area  y paquete esten dentro de los arreglos si no para agregarlos*/
               let find_area=areas.filter(option => option.value == area.value)
               if(find_area.length<1){

                 areas.push( area);
                 
               }
               usuario.area_id=area.value;
              
            }else{
              usuario.area_id=areas[0].value;
            }

            api2().get("/get_paquetes_area/"+usuario.area_id+'?filtro=1')
            .then(function(response){
              if(response.status === 200){
                 let cantidad_max
                let paquetes = response.data.paquetes;
                let cantidades=[];
                

                if(area!=null){
                             paquetes=[];
                               let find_paquete=paquetes.filter(option => option.value == paquete.value)
                               if(find_paquete.length<1)
                                paquetes.push( paquete); 

                                // console.log('paquete--->',paquete)  
                                // console.log('registro--->',registro)   
                               /*colocamos el precio y el total correcto */
                               usuario.paquete_id=paquete.value;
                               usuario.precio_unitario=registro.precio_unitario;
                               usuario.total=registro.total;
                                 cantidades=[];
                                 cantidad_max=paquete.cantidad;
                                 if(area.agrupar_reservaciones==1)
                                    cantidad_max=area.cantidad_max;

                                for (var i = cantidad_max ; i >= 1; i--) {
                                 cantidades.push( {label: i, value: i});           
                                }
                                let find_cantidad=cantidades.filter(option => option.value == usuario.cantidad)
                               if(find_cantidad.length<1)
                                cantidades.push( {label: usuario.cantidad, value: usuario.cantidad});
                            }else{
                              if(paquetes.length>0){
                                 usuario.paquete_id=paquetes[0].value;
                                 usuario.precio_unitario=paquetes[0].precio;
                                 usuario.total=paquetes[0].precio;


                                  cantidad_max=paquetes[0].cantidad;
                                 if(areas[0].agrupar_reservaciones==1)
                                    cantidad_max=areas[0].cantidad_max;

                                  for (var i = cantidad_max ; i >= 1; i--) {
                                   cantidades.push( {label: i, value: i});           
                                  }
                              }
                            }

                _self.setState({
                  paquetes : paquetes,
                  areas : areas,
                  usuario:usuario,
                  cantidades:cantidades,
                }, () => { });
              }

            });




          }else{
            let paquetes=[];
              if(area!=null){
                              areas=[];
                              
                               /*revisamos que el area  y paquete esten dentro de los arreglos si no para agregarlos*/
                               let find_area=areas.filter(option => option.value == area.value)
                               if(find_area.length>1){
                                 areas=[];
                                 areas.push( area);
                               }
                               let find_paquete=paquetes.filter(option => option.value == paquete.value)
                               if(find_paquete.length>1)
                                 paquetes.push( paquete);    
                               /*colocamos el precio y el total correcto */
                               usuario.precio_unitario=precio;
                               usuario.total=usuario.cantidad*precio;
                            }

             _self.setState({
              areas : areas,
              paquetes : paquetes,
              usuario:usuario,
            })
          }
          
         
        }

    });


 }

  componentDidMount(){
    

    let _self = this;
    let {usuario} = this.state;
    let {params} = this.props.match;



/*hacemos dos llamadas direferentes  ya que si damos de baja algun paquete , si es una reservacion ya creada  buscamos todos los paquetes y areas sin importar su estado  */

    if(params.id) {
      api2().get("/getreservacion/"+params.id)
      .then(function(response){
        if(response.status === 200){
          usuario = response.data.reservacion;
          // usuario.fecha_nacimiento=moment(new Date(usuario.fecha_nacimiento)).format('YYYY-MM-DD')
          usuario.fecha=moment(new Date(usuario.fecha)).format('YYYY-MM-DD')
          let horas=[]
           horas.push( {label:usuario.hora, value: usuario.hora,});
          _self.setState({
            usuario : usuario,
            horas:horas
          }, () => { 
            _self.get_area_paquetes_inicio(response.data.area,response.data.paquete,usuario.precio_unitario,response.data.reservacion);
          });


        }

      });

    }else{
      this.get_area_paquetes_inicio();
    }






  }




   handleSelectChange=(select, name )=> {
      let {usuario} = this.state;
     let _self = this;
      const value = select === null ? null : select.value;
      usuario[name] = value;
      // console.log('value---->', value);
      // console.log('usuario---->',usuario);
      // console.log('name---->',name);
      this.setState({usuario : usuario}, () => {
            if(name=='area_id'){
              _self.get_paquetes(value);
            }
            if(name=='paquete_id'){


              if(_self.state.areas.filter(option => option.value == usuario.area_id)[0].agrupar_reservaciones==0){
                // console.log('cantidad paquete')
                _self.get_cantidades(_self.state.paquetes.filter(option => option.value == value)[0].cantidad);
              }else{
                // console.log('cantidad area')
                _self.get_cantidades(_self.state.areas.filter(option => option.value == usuario.area_id)[0].cantidad_max);
              }
              
                /*actualizamos el precio y el total*/
                let precio=_self.state.paquetes.filter(option => option.value == value)[0].precio;
                usuario.precio_unitario=_self.state.paquetes.filter(option => option.value == value)[0].precio;
                usuario.total=_self.state.paquetes.filter(option => option.value == value)[0].precio;
                this.setState({usuario : usuario ,horas : []}, () => {  });
              // console.log('paquete---->',_self.state.paquetes.filter(option => option.value == value)[0].cantidad)
            }

            if(name=='cantidad'){

                usuario.total=usuario.precio_unitario*value;
               this.setState({usuario : usuario, horas : []}, () => {  });
            }


         });
    }


  handleSubmit(evt){

    evt.preventDefault();
    if(this.props.auth.user.rol<99)
      return

    let _self = this;
    let {usuario} = this.state;
    var patt = new RegExp("[a-zA-Z0-9]?[a-zA-Z0-9]{11}[a-zA-Z0-9]$", "i");
     // _self.setState({
     //        submit : true
     //      })

      if (usuario.paquete_id==0) {
       swal.fire("Alerta!", 'Favor de Seleccionar un Paquete', "warning");
       _self.setState({
                    submit : false
                  });
       return
    }


    usuario.fecha_registro=moment(usuario.fecha_registro).format('Y-MM-DD');



     

     /*ponemos la duracion y el nombre del paquete */
     usuario.nombre_paquete =_self.state.paquetes.filter(option => option.value == usuario.paquete_id)[0].label;
     usuario.duracion =_self.state.areas.filter(option => option.value == usuario.area_id)[0].duracion;
 




    let emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!emailRegex.test(usuario.correo)) {
           swal.fire("Alerta!", 'Correo No valido', "warning");
           _self.setState({
                        submit : false
                      });
           return
        }

    if (usuario.anticipo>usuario.total) {
       swal.fire("Alerta!", 'El Anticipo no puede ser mayor al total', "warning");
       _self.setState({
                    submit : false
                  });
       return
    }
    if (usuario.anticipo<0) {
       swal.fire("Alerta!", 'El Anticipo no puede ser Menor a 0', "warning");
       _self.setState({
                    submit : false
                  });
       return
    }


    let date_now =moment().subtract(18, 'years')
    let fecha_nacimiento=moment(new Date(usuario.fecha_nacimiento+' 00:00:00'))
    if (fecha_nacimiento>=date_now) {
       swal.fire("Alerta!", 'No  es mayor de edad (18 años)', "warning");
       _self.setState({
                    submit : false
                  });
       return
    }

    /*validamos que seleccionen una  hora*/
    // console.log('usuario.hora.length--->',usuario.hora.length)
     if (usuario.hora.length==0) {
       swal.fire("Alerta!", 'Favor de Seleccionar una hora', "warning");
       _self.setState({
                    submit : false
                  });
       return
    }




          api().post(`/created_updated_reservaciones`,usuario)
        .then(function(response)
        {
           if(response.status){
                  if(response.data.estado){
                    swal.fire("Bien hecho!", "La Informacion fue Guardada con éxito!", "success");
                    _self.props.history.push('/app/reservaciones');
                  }else{
                    swal.fire("Alerta!", response.data.mensaje, "warning");
                    _self.setState({
                        submit : false
                      });
                  }
                }else{
                  // console.log('error')
                  swal.fire({
                            type: 'error',
                            title: 'Solicitar ayuda a un Administrador',
                          })
                  _self.setState({
                        submit : false
                      });
                }
        }).catch(() => {
          swal.fire("Alerta!", 'Solicitar ayuda a un Administrador', "warning");
         
            })



            
        
  }



  handeInputChange(event){
    let {usuario} = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    usuario[name] = value;

    if(name=='fecha'){
      this.setState({
        usuario : usuario,
        horas:[]
    });
    }else{
      this.setState({
        usuario : usuario
    });
    }
   
    
  }


  buscar_horarios=(evt)=>{
     evt.preventDefault();
     let _self = this;
     let {usuario} = this.state;
     /*validamos  el area_id y el paquete id   */
     if(usuario.area_id==0){
      swal.fire("Alerta!", 'Favor de Seleccionar un Area', "warning");
      return
     }
     if(usuario.paquete_id==0){
      swal.fire("Alerta!", 'Favor de Seleccionar un Paquete', "warning");
      return
     }


     api().post(`/get_horarios_paquetes`,usuario)
        .then(function(response)
        {
           if(response.status){
                  if(response.data.estado){

                    if(response.data.horarios.length>0)
                      usuario.hora= response.data.horarios[0].value;
                    
                    /*guardamos los horarios */
                     _self.setState({
                      usuario:usuario,
                        horas : response.data.horarios
                      });

                   
                  }else{
                    swal.fire("Alerta!", response.data.mensaje, "warning");
                    usuario.hora='';
                    
                    /*guardamos los horarios */
                     _self.setState({
                      usuario:usuario,
                        horas : []
                      });
                  }
                }else{
                  // console.log('error')
                  swal.fire({
                            type: 'error',
                            title: 'Solicitar ayuda a un Administrador',
                          })
                  usuario.hora='';
                    
                    /*guardamos los horarios */
                     _self.setState({
                      usuario:usuario,
                        horas : []
                      });
                }
        }).catch(() => {
          swal.fire("Alerta!", 'Solicitar ayuda a un Administrador', "warning");
          usuario.hora='';
                    
                    /*guardamos los horarios */
                     _self.setState({
                      usuario:usuario,
                        horas : []
                      });
         
            })


    

  }







 

  

    regresar=(evt)=>
    {
      evt.preventDefault();
      
      this.props.history.push('/app/reservaciones');
    }
  render() {
     let {usuario,submit,experiencia,educacion} = this.state;
     // console.log('state---->',this.state)
     let solo_ver=this.props.auth.user.rol==99?false:true
    return (
      
        <div className="content">
          <Row className='justify-content-center'>
          
            <Col md="12">
              <Card className="card-user">
                <CardHeader>
                  <CardTitle tag="h5">Reservación</CardTitle>
                </CardHeader>
                <CardBody>


                <Row>
                <Col>
            
                    <TabContent activeTab={this.state.activeTab}>
                    
                    
                      <TabPane tabId="1">
                      <Form onSubmit={this.handleSubmit}>
                      <Row className='pt-4'>
                        <div className="col-12 col-md-2 text-center"> 
                        <div className="form-group">
                          <label className='col-form-label'>Estado:</label>
                          <Select  placeholder="" type="text" isDisabled={solo_ver}  name="moneda" options={estados}  value={estados.filter(option => option.value == usuario.estado)}  onChange={ (select) => { this.handleSelectChange(select, 'estado') } } clearable={false} />
                        </div>
                    </div>
                    <div className="col-12 col-md-2 text-center"> 
                        <div className="form-group">
                          <label className='col-form-label'>Idioma:</label>
                          <Select  placeholder="" type="text" isDisabled={solo_ver} name="moneda" options={idiomas}  value={idiomas.filter(option => option.value == usuario.idioma)}  onChange={ (select) => { this.handleSelectChange(select, 'idioma') } } clearable={false} />
                        </div>
                    </div>
                    <Col className="pr-1" md="4">
                        <FormGroup>
                          <label>Stripe ID</label>
                          <Input
                            placeholder=""
                            type="text"
                            name="token_stripe"
                            required
                            value={usuario.token_stripe}
                            readOnly                          />
                        </FormGroup>
                      </Col>
                      </Row>
                    <Row >
                    <div className="col-12 col-md-4 text-center"> 
                        <div className="form-group">
                          <label className='col-form-label'>Area:</label>
                          <Select  placeholder="" type="text" isDisabled={solo_ver}  name="moneda" options={this.state.areas}  value={this.state.areas.filter(option => option.value == usuario.area_id)}  onChange={ (select) => { this.handleSelectChange(select, 'area_id') } } clearable={false} />
                        </div>
                    </div>
                    <div className="col-12 col-md-4 text-center"> 
                        <div className="form-group">
                          <label className='col-form-label'>Paquetes:</label>
                          <Select  placeholder="" type="text" isDisabled={solo_ver}  name="moneda" options={this.state.paquetes}  value={this.state.paquetes.filter(option => option.value == usuario.paquete_id)}  onChange={ (select) => { this.handleSelectChange(select, 'paquete_id') } } clearable={false} />
                        </div>
                    </div>
                    <div className="col-12 col-md-2 text-center"> 
                        <div className="form-group">
                          <label className='col-form-label'>Cantidad:</label>
                          <Select  placeholder="" type="text" isDisabled={solo_ver} name="moneda" options={this.state.cantidades}  value={this.state.cantidades.filter(option => option.value == usuario.cantidad)}  onChange={ (select) => { this.handleSelectChange(select, 'cantidad') } } clearable={false} />
                        </div>
                    </div>
                    <div className="col-12 col-md-2 text-center"> 
                        <div className="form-group">
                          <label className='col-form-label'>Aforo:</label>
                          <Select  placeholder="" type="text" isDisabled={solo_ver}  name="moneda" options={sino}  value={sino.filter(option => option.value == usuario.aforo)}  onChange={ (select) => { this.handleSelectChange(select, 'aforo') } } clearable={false} />
                        </div>
                    </div>
                    <div className="col-md-3"> 
                    <div className="form-group">
                        <label >Fecha *:</label>
                        <input type="date" readOnly={solo_ver}  onChange={ this.handeInputChange} className="form-control"   name="fecha" value={usuario.fecha} />
                    </div>
                </div>
                <div className="col-md-1"> 
                    <div className="form-group pt-3">
                     {!solo_ver&&
                      <Button
                          className="btn-round"
                          color="warning"
                          onClick={this.buscar_horarios}>
                          Buscar
                        </Button>
                      }
                    </div>
                  </div>
                <div className="col-12 col-md-2 text-center"> 
                        <div className="form-group">
                          <label >Hora:</label>
                          <Select  placeholder="" isDisabled={solo_ver} type="text"  name="hora" options={this.state.horas}  value={this.state.horas.filter(option => option.value == usuario.hora)}  onChange={ (select) => { this.handleSelectChange(select, 'hora') } } clearable={false} />
                        </div>
                    </div>
                  
                 <div className="col-md-2"> 
                    <div className="form-group">
                        <label >Precio:</label>
                        <input type="text"   readOnly className="form-control"   name="precio_unitario" value={usuario.precio_unitario} />
                    </div>
                </div>
                 <div className="col-md-2"> 
                    <div className="form-group">
                        <label >Total *:</label>
                        <input type="text"  readOnly  className="form-control"   name="total" value={usuario.total} />
                    </div>
                </div>
                <div className="col-md-2"> 
                    <div className="form-group">
                        <label >Anticipo *:</label>
                        <input type="number"  step={1}   onChange={ this.handeInputChange}  className="form-control"   name="anticipo" value={usuario.anticipo} />
                    </div>
                </div>



                <Col className="pr-1" md="12">
                        
                          <h3>Información del Cliente</h3>
                         
                      </Col>




           
                     
                       <Col md="12">
                       <Row>
                         <Col className="pr-1" md="4">
                        <FormGroup>
                          <label>Nombre</label>
                          <Input
                            placeholder="Nombre(s)"
                            type="text"
                            name="cliente"
                            required
                            value={usuario.cliente}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="4">
                        <FormGroup>
                          <label>Apellidos</label>
                          <Input
                            placeholder=""
                            type="text"
                            name="apellido"
                            required
                            value={usuario.apellido}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>
                      
                      <Col className="pr-1" md="4">
                        <FormGroup>
                          <label>Correo</label>
                          <Input
                            placeholder=""
                            type="text"
                            name="correo"
                            required
                            value={usuario.correo}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>
                       </Row> 
                      </Col>
                       <Col md="4">
                       
                      </Col>
                      
                    </Row>
                    <Row>
                    <Col className="pr-1" md="4">
                        <FormGroup>
                          <label>Telefono</label>
                          <Input
                            placeholder=""
                            type="text"
                            name="telefono"
                            required
                            value={usuario.telefono}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>
                      
                       <Col className="pr-1" md="2">
                        <FormGroup>
                          <label>Fecha Nacimiento</label>
                          <Input
                            placeholder=""
                            type="date"
                            name="fecha_nacimiento"
                            required
                            value={usuario.fecha_nacimiento}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <div className="col-12 col-md-2 text-center"> 
                        <div className="form-group">
                          <label >Identificación:</label>
                          <Select  placeholder="" type="text"  name="hora" options={identificaciones}  value={identificaciones.filter(option => option.value == usuario.identificacion)}  onChange={ (select) => { this.handleSelectChange(select, 'identificacion') } } clearable={false} />
                        </div>
                    </div>

                    <Col className="pr-1" md="12">
                        <FormGroup>
                          <label>Peticion Especial(Cliente)</label>
                          <textarea
                            placeholder=""
                            type="text"
                            row={3}
                            name="peticion_especial"
                            required
                            value={usuario.peticion_especial}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="12">
                        <FormGroup>
                          <label>Nota (Admin)</label>
                          <textarea
                            placeholder=""
                            type="text"
                            row={3}
                            name="nota_interna"
                          
                            value={usuario.nota_interna}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>


         
                      

                      

                     
                      
                    </Row>
                  
                    <Row>
                      <div className="update ml-auto mr-auto">
                      {!solo_ver&&
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                          disabled={submit}
                        >
                          Guardar
                        </Button>
                      }
                        
                        <Button
                          className="btn-round"
                          color="warning"
                          onClick={this.regresar}>
                          Regresar
                        </Button>
                      </div>
                    </Row>
                  </Form>
                       </TabPane>                      
                    </TabContent>
                </Col>
                  
                </Row>

                  
                </CardBody>
              </Card>
            </Col>
          </Row>
          
          
        </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
    return {
        auth : state.auth,
    }
};

export default connect(mapStateToProps, actions)(User)

