/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.jsx";
import Notifications from "views/Notifications.jsx";
import Icons from "views/Icons.jsx";
import Typography from "views/Typography.jsx";
import TableList from "views/Tables.jsx";
import Maps from "views/Map.jsx";
import UserPage from "views/User.jsx";
import UpgradeToPro from "views/Upgrade.jsx";







export const admin = [
  




  

  {
    path: "/usuarios",
    name: "Usuarios",
    icon: "nc-icon nc-single-02",
  
    layout: "/app",
    exact: true,
  },
    {
    path: "/bloqueos",
    name: "Bloqueos",
    icon: "nc-icon nc-single-02",
  
    layout: "/app",
    exact: true,
  },
  {
    path: "/areas",
    name: "Areas",
    icon: "nc-icon nc-single-02",
  
    layout: "/app",
    exact: true,
  },
  {
    path: "/reservaciones",
    name: "Reservaciones",
    icon: "nc-icon nc-single-02",
  
    layout: "/app",
    exact: true,
  },

 
 
];

export const empleado = [
  
];
