import axios from 'axios';

export const API_UR2 = 'https://gestor.casafridavalle.com/';
export const API_URL2 = 'https://gestor.casafridavalle.com/api';
export const API_URL = 'https://gestor.casafridavalle.com/api';

 // export const API_UR2 = 'http://localhost:8083/';
 // export const API_URL2 = 'http://localhost:8083/api';
 // export const API_URL = 'http://localhost:8083/api';

// export const API_UR2 = 'https://casafridatest.wenatives.com/';
// export const API_URL2 = 'https://casafridatest.wenatives.com/api';
// export const API_URL = 'https://casafridatest.wenatives.com/api';


 
// http://api.vegex.wenatives.com/
 //carpeta para el static  paper-dashboard-react

 //9-7Ee2,F~#Y=

 //laravel_nomina/storage/app/xmls
export const request = axios.create({
	baseURL 		: API_URL,
	responseType 	: 'json'
});

export const api = () => {

	let token = localStorage.getItem('session_token_pos');

	

	let AuthorizationToken = "Bearer "+token;

	return axios.create({
		baseURL 		: API_URL,
		responseType 	: 'json',
		headers 		: {'Authorization': AuthorizationToken }

	});
}




export const api_formdata = () => {

	let token = localStorage.getItem('session_token_pos');

	// token = JSON.parse(token);

	let AuthorizationToken = "Bearer "+token;

	return axios.create({
		baseURL 		: API_URL,
		responseType 	: 'json',
		headers 		: {'Authorization': AuthorizationToken,
							'Content-Type': 'multipart/form-data', }

	});

}

export const api_descarga = () => {

	let token = localStorage.getItem('session_token_pos');

	// token = JSON.parse(token);

	let AuthorizationToken = "Bearer "+token;

	return axios.create({
		baseURL 		: API_URL,
		responseType 	: 'blob',
		headers 		: {'Authorization': AuthorizationToken,
							'Content-Type': 'multipart/form-data', }

	});

}



export const api2 = () => {

	let token = localStorage.getItem('session_token_pos');

	// token = JSON.parse(token);

	let AuthorizationToken = "Bearer "+token;

	return axios.create({
		baseURL 		: API_URL2,
		responseType 	: 'json',
		headers 		: {'Authorization': AuthorizationToken }

	});
}